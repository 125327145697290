<template>
  <v-container>
    <!-- <v-tabs :vertical="isOnPC"> -->
      <!-- <v-tab v-for="tab in menu" :key="tab.url" :to="`/profil/${tab.url}`" v-text="tab.text" /> -->
      <!-- <v-tabs-items> -->
        <router-view />
      <!-- </v-tabs-items> -->
    <!-- </v-tabs> -->
  </v-container>
</template>

<script>
export default {
  name: 'About',

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({
      menu: [
        { url: 'sejarah/', text: 'Sejarah' },
        { url: 'struktur-organisasi/', text: 'Struktur Organisasi' }
      ]
    })
  }
}
</script>
